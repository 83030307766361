export interface AppNotification {
  title: string;
  message: string;
  type?: NotificationType;
  id: number;
  siteName?: string;
  payload?: any;
  error?: string;
}

export enum NotificationType {
  warning = 'warning',
  error = 'error'
}

export interface NotificationStyleData {
  height?: string;
  headerHeight?: string;
  notificationRight?: string;
  headerWidth?: string;
  notificationWidth?: string;
  notificationBottom?: string;
  multipleNotificationBottom?: string;
  isTooltip?: boolean;
  width?: string;
  notificationtransform?: string;
  notificationLeft?: string;
}
