import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppNotification, NotificationStyleData, NotificationType } from '../app-notifications.model';

@Component({
  selector: 'app-notifications',
  templateUrl: './app-notifications.component.html',
  styleUrls: ['./app-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('notificationAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }), //apply default styles before animation starts
        animate('650ms ease-in-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [animate('250ms ease-in-out', style({ transform: 'translateX(100%)' }))])
    ])
  ]
})
export class AppNotificationsComponent {
  @Input() notificationData: AppNotification[];
  @Input() notificationstyles: NotificationStyleData = {
    height: 'fit-content',
    notificationRight: '1em',
    headerWidth: '200px',
    notificationWidth: '390px',
    notificationBottom: '16px',
    multipleNotificationBottom: '2.85rem',
    isTooltip: true,
    width: '285px',
    notificationLeft: '',
    notificationtransform: ''
  };

  @Output() onclose = new EventEmitter<AppNotification>();
  @Output() ontryAgain = new EventEmitter<AppNotification>();
  notificationType = NotificationType;
  trackByID = (index: number, item: any) => item?.id;

  constructor() { }

  /**
   * Method to handle status while close icon click.
   */
  onClose(notification: AppNotification): void {
    this.onclose.emit(notification);
  }

  /**
   * Method to handle try again in error notification.
   */
  onTryAgain(notification: AppNotification): void {
    this.ontryAgain.emit(notification);
  }
}
