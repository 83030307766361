<div
  class="app-notifications"
  [style.right]="notificationstyles.notificationRight"
  [style.left]="notificationstyles.notificationLeft"
  [style.transform]="notificationstyles.notificationtransform"
  [style.bottom]="notificationData?.length === 1 ? notificationstyles.notificationBottom : notificationstyles.multipleNotificationBottom"
  [ngClass]="{ 'single-notification': notificationData?.length === 1 }"
  >
  @for (notification of notificationData; track trackByID($index, notification)) {
    @if (notification) {
      <div
        class="notification"
        [style.height]="notificationstyles.height"
        [style.maxWidth]="notificationstyles.notificationWidth"
        [style.width]="notificationstyles?.width"
        [ngClass]="notification.type"
        [@notificationAnimation]
        >
        <div class="notification-header" [style.height]="notificationstyles.headerHeight">
          <div class="info-icon">
            <img [src]="'app-notifications/images/' + notification.type + '.svg'" [alt]="'prefix' + notification.type" />
          </div>
          <div
            class="notification-title"
            [style.width]="notificationstyles.headerWidth"
            cc-tooltip="{{ notification.title }}"
            tooltipPosition="top-left"
            [isEllipseEnabled]="!notificationstyles.isTooltip ? false : true"
            >
            {{ notificationData?.length > 1 ? notification.id + ': ' : '' }} {{ notification.title }}
          </div>
          <div cc-tooltip=" {{ 'app.close' | transloco }} " class="notification-close" (click)="onClose(notification)">
            <img src="app-notifications/images/close-icon.svg" alt="suffix icon" />
          </div>
        </div>
        @if (notification.message) {
          <p class="notification-content notification-message" [innerHTML]="notification.message"></p>
        }
        @if (notification.type === notificationType.error) {
          <p
            class="notification-content notification-try-again"
            (click)="onTryAgain(notification)"
            >
            {{ notification.error | transloco }}
          </p>
        }
      </div>
    }
  }
</div>
